<template>
  <header dir="rtl">
    <PopUp />
    <ViewOne />
    <ViewTwo />
    <ImageVideoPopup /> 
    <HomeReasons />
    <VideoSliderFour />   
    <HomeVideo />
    <HomeCounter />
    <HomeCertificate />
    <HomeCircle />
    <HomeSwiper />
    <VideoSliderThree />
    <VideoSliderFive />
    <VideoSliderTwo />
    <ViewThree />
  </header>
</template>

<script>
  import ViewOne from '../../components/ar/ViewOne.vue'; 
  import ViewTwo from '../../components/ar/ViewTwo.vue'; 
  import ViewThree from '../../components/ar/ViewThree.vue'; 
  import HomeCounter from '../../components/ar/HomeCounter.vue';
  import HomeCircle from '../../components/ar/HomeCircle.vue';
  import HomeSwiper from '../../components/ar/HomeSwiper.vue';
  import ImageVideoPopup from '@/components/ar/ImageVideoPopup.vue';
  import VideoSliderTwo from '../../components/ar/VideoSliderTwo.vue';
  import VideoSliderThree from '../../components/ar/VideoSliderThree.vue';
  import VideoSliderFour from '../../components/ar/VideoSliderFour.vue';
  import VideoSliderFive from '../../components/ar/VideoSliderFive.vue';
  import HomeReasons from '../../components/ar/HomeReasons.vue';
  import PopUp from '../../components/ar/PopUp3.vue';
  import HomeCertificate from "@/components/ar/HomeCertificate.vue";
  import HomeVideo from '../../components/ar/HomeVideo';

  export default {
    name: 'HomePage',
    components: {
      HomeCertificate,
      ViewOne,
      ViewTwo,
      ViewThree,
      HomeCounter,
      HomeCircle,
      HomeSwiper,
      ImageVideoPopup,
      VideoSliderTwo,
      VideoSliderThree,
      VideoSliderFour,
      VideoSliderFive,
      HomeReasons,
      PopUp,
      HomeVideo
    },
    mounted() {
      const script = document.createElement('script');
      script.type = 'application/ld+json';
      script.textContent = JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "أكاديمية مونجلش",
        "alternateName": "Monglish International Academy",
        "url": "https://monglish.co.uk/",
        "logo": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT84tLPU7v2J2zh-gMtUb4guYJs9ZQb9aG20w&s",
        "sameAs": [
          "https://www.facebook.com/Monglishkids/",
          "https://www.instagram.com/monglishacademy?igsh=MTByNm42empsdzF4aw==",
          "https://monglish.co.uk/",
          "https://www.youtube.com/channel/UCdzXmu8FEDyHAQ5rpB8F2ag",
          "https://www.linkedin.com/company/monglish-academy/",
          "https://x.com/monglish_uk?t=06bmBVLpWeZ8jrdJpzK_Eg&s=09"
        ]
      });
      document.head.appendChild(script);
    }
  }
</script>
