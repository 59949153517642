<template>
    <div class="body">
        <div class="container">
        <h1>تم تسجيل طلبك بنجاح</h1>
        <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" fill="#e56311">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <g data-name="Layer 2" id="Layer_2">
                    <g data-name="E408, Success, Media, media player, multimedia" id="E408_Success_Media_media_player_multimedia">
                        <circle class="cls-1" cx="256" cy="256" r="246"></circle>
                        <polyline class="cls-1" points="115.54 268.77 200.67 353.9 396.46 158.1"></polyline>
                    </g>
                </g>
            </g>
        </svg>
        <h3>تمت عمليه الدفع برجاء التأكد من الايميل الخاص بك</h3>
        <button id="return-button-ar" @click="goHome">العودة إلى الصفحة الرئيسية</button>
    </div>
    </div>
</template>

<script>
    export default {
    name: 'PaymentSuccess',
    methods: {
        goHome() {
            this.$router.push('/');
        }
    }
    }
</script>

<style scoped>
    .cls-1 {
        fill: none;
        stroke: #f47d21;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 20px;
    }
    @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;600&display=swap');
    .body {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        background: linear-gradient(to left, #165e84 50%, #f47d21 50%);
        margin-top: -85px;
        direction: rtl;
        font-family: 'Cairo', sans-serif;
    }

    .container {
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
        width: 80%;
        max-width: 600px;
        padding: 30px 20px;
        text-align: center;
    }

    h1 {
        color: #f47d21;
        margin-bottom: 20px;
        font-size: 36px;
        font-weight: 600;
    }

    h3 {
        color: #165e84;
        font-size: 24px;
        margin-top: 20px;
        font-weight: 400;
    }

    svg {
        width: 120px;
        height: 120px;
        margin: 0 auto;
        display: block;
        animation: bounce 1s ease-in-out infinite;
    }

    @keyframes bounce {
        0%, 100% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-10px);
        }
    }

    #return-button-ar {
        margin-top: 20px;
        padding: 10px 20px;
        font-size: 18px;
        color: white;
        background-color: #f47d21;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
    }

    #return-button-ar:hover {
        background-color: #e56311;
    }
</style>
