<template>
  <div id="app">
    <component :is="currentNavbar" />
    <WhatsappButton />
    <router-view />
    <component :is="currentFooter" />
  </div>
</template>

<script>
import MonglishNavbarAr from './components/ar/MonglishNavbar.vue';
import MonglishFooterAr from './components/ar/MonglishFooter.vue';
import MonglishNavbarEn from './components/en/MonglishNavbar.vue';
import MonglishFooterEn from './components/en/MonglishFooter.vue';
import WhatsappButton from './components/WhatsappButton.vue';

export default {
  name: 'App',
  data() {
    return {
      currentNavbar: 'MonglishNavbarAr',
      currentFooter: 'MonglishFooterAr',
    };
  },
  watch: {
    '$route'(to) {
      if (to.path.startsWith('/en')) {
        this.currentNavbar = 'MonglishNavbarEn';
        this.currentFooter = 'MonglishFooterEn';
      } else {
        this.currentNavbar = 'MonglishNavbarAr';
        this.currentFooter = 'MonglishFooterAr';
      }
    }
  },
  components: {
    MonglishNavbarAr,
    MonglishFooterAr,
    MonglishNavbarEn,
    MonglishFooterEn,
    WhatsappButton,
  }
};
</script>

<style>
@font-face {
  font-family: 'DIN Next LT Arabic';
  src: url('@/assets/fonts/DINNextLTArabic-Regular-3.ttf') format('truetype');
}
@font-face {
  font-family: 'DIN Next LT Arabic-n';
  src: url('@/assets/fonts/DINNextLTArabic-Heavy.ttf') format('truetype');
  font-weight: 800;
}
@font-face {
  font-family: 'DIN Next LT Arabic-n';
  src: url('@/assets/fonts/DINNextLTArabic-Regular-3.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'DIN Next LT Arabic-n';
  src: url('@/assets/fonts/DINNextLTArabic-Light.ttf') format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'DIN Next LT Arabic-n';
  src: url('@/assets/fonts/DINNextLTArabic-UltraLight.ttf') format('truetype');
  font-weight: 100;
}
</style>
