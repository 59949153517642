<template>
  <div class="slider-3d">
    <h1>Mongi-Immerse</h1>
    <div class="carousel-container">
      <button class="slider-nav left" @click="moveLeft">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
        </svg>
      </button>

      <transition-group name="slide" tag="div" class="carousel-track">
        <div v-for="(item, index) in generateItems()" :key="index" :class="['item', 'level' + item.level]">
          <img :src="item.src" :alt="'Image ' + index" @click="openPopup(item.videoSrc)" />
        </div>
      </transition-group>

      <button class="slider-nav right" @click="moveRight">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
        </svg>
      </button>
    </div>

    <div v-if="isPopupOpen" class="popup-overlay" @click="closePopup">
      <div class="popup-content" @click.stop>
        <button class="close-button" @click="closePopup">&times;</button>
        <iframe
          v-if="currentVideoSrc"
          :src="currentVideoSrc"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          class="popup-video"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isPopupOpen: false,
      currentVideoSrc: '',
      active: 0,
      items: [
        { src: require('@/assets/images/video/15.png') , videoSrc: 'https://www.youtube.com/embed/rzHfZxBceFo?autoplay=1'},
        { src: require('@/assets/images/video/16.png') , videoSrc: 'https://www.youtube.com/embed/0-wqA_sC2ZY?autoplay=1'},
        { src: require('@/assets/images/video/17.png') , videoSrc: 'https://www.youtube.com/embed/DzvbteTOIk8?autoplay=1'},
        { src: require('@/assets/images/video/18.png') , videoSrc: 'https://www.youtube.com/embed/uphkfMfFFj4?autoplay=1'},
        { src: require('@/assets/images/video/19.png') , videoSrc: 'https://www.youtube.com/embed/cmB1pPqc-EE?autoplay=1'},
      ],
      autoSlideInterval: null
    };
  },
  methods: {
    openPopup(videoSrc) {
      this.currentVideoSrc = videoSrc;
      this.isPopupOpen = true;
    },
    closePopup() {
      this.isPopupOpen = false;
      this.currentVideoSrc = '';
    },
    generateItems() {
      const items = [];
      for (let i = this.active - 2; i <= this.active + 2; i++) {
        let index = i;
        if (i < 0) index = this.items.length + i;
        if (i >= this.items.length) index = i % this.items.length;

        items.push({
          src: this.items[index].src,
          videoSrc: this.items[index].videoSrc, 
          level: this.active - i
        });
      }
      return items;
    },
    moveLeft() {
      this.active = (this.active - 1 + this.items.length) % this.items.length;
    },
    moveRight() {
      this.active = (this.active + 1) % this.items.length;
    },
    autoSlide() {
      this.autoSlideInterval = setInterval(() => {
        this.moveRight();
      }, 3000);
    },
    stopAutoSlide() {
      clearInterval(this.autoSlideInterval);
    }
  },
  mounted() {
    this.autoSlide();
  },
  beforeUnmount() {
    this.stopAutoSlide();
  }
};
</script>

<style scoped>
.slider-3d {
  background-color: #fff;
  padding: 3% 1%;
}

.carousel-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: auto;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center; 
  overflow: hidden;
  perspective: 1000px; 
}

.carousel-track {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  transform-style: preserve-3d; 
  transition: transform 0.8s ease;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}

.slide-enter-active, .slide-leave-active {
  animation: slideIn 0.8s ease forwards;
}

.slide-leave-to {
  animation: slideOut 0.8s ease forwards;
}

h1 {
  color: #165e84;
  font-size: 48px;
  font-weight: 700;
  font-family: 'DIN Next LT Arabic-n';
  text-align: center;
  padding: 0 0 2% 0;
}

.slider-nav {
  color: #165e84;
  cursor: pointer;
  position: absolute;
  z-index: 10;
  background-color: transparent;
  transition: color 0.3s ease, transform 0.3s ease;
  border: none;
}

.slider-nav:hover {
  color: #2178a6;
  transform: scale(1.1);
}

.slider-nav:active {
  color: #fff;
  transform: scale(1);
}

.slider-nav.left {
  left: 5%;
}

.slider-nav.right {
  right: 5%;
}

.item {
  position: relative;
  text-align: center;
  transition: transform 0.8s ease, opacity 0.8s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  cursor: pointer;
}

.level-2 {
  transform: translateX(91px) rotateY(-10deg) scale(0.6);
  opacity: 0.5;
}

.level-1 {
  transform: translateX(-1px) rotateY(-5deg) scale(0.8);
  opacity: 0.7;
}

.level0 {
  transform: translateX(0) rotateY(0deg) scale(1);
  opacity: 1;
}

.level1 {
  transform: translateX(1px) rotateY(5deg) scale(0.8);
  opacity: 0.7;
}

.level2 {
  transform: translateX(-91px) rotateY(10deg) scale(0.6);
  opacity: 0.5;
}

.popup-overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  width: 26vw;
  height: 95vh;  
  overflow: hidden;
  position: relative;
}

.popup-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes popupIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.close-button {
  position: absolute;
  top: 15px;
  left: 20px;
  font-size: 4rem;
  cursor: pointer;
  transition: color 0.3s ease; 
}

.close-button:hover {
  color: #d9534f; 
}

@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  .slider-nav.left {
    left: 0;
  }

  .slider-nav.right {
    right: 0;
  }

  .popup-content {
    width: 95%;
    height: auto;
  }

  .popup-video {
    height: calc(75vw * (16 / 9));
    width: 100%;
  }
}
</style>
