<template>  
  <!--Section Two Content-->
  <div class="kids-sec2">
       <h1>مزايا دورات مونجلش
       <br>تفتح أبواب المستقبل</h1>
    <div class="kids-sec2-cards">
      <div class="sec2-card">
        <svg xmlns="http://www.w3.org/2000/svg" width="106" height="272" viewBox="0 0 106 272" fill="none">
          <path
            d="M49.1672 63.2H48.3672L5.16719 92.8C2.23386 94.6667 0.76719 93.8667 0.76719 90.4V40.8C0.76719 38.6667 1.70052 36.8 3.56719 35.2L49.1672 2.39999C51.0339 0.799995 53.4339 -2.86102e-06 56.3672 -2.86102e-06H101.167C103.834 -2.86102e-06 105.167 1.33333 105.167 3.99999V268C105.167 270.667 103.834 272 101.167 272H53.1672C50.5005 272 49.1672 270.667 49.1672 268V63.2Z"
            fill="url(#paint0_linear_615_29299)"></path>
          <defs>
            <linearGradient id="paint0_linear_615_29299" x1="64" y1="-2" x2="64" y2="272"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#9C9C9C"></stop>
              <stop offset="1" stop-color="#F1F1F1"></stop>
            </linearGradient>
          </defs>
         </svg>
        <h1>مواعيد دروس التدريب</h1>
        <p>مواعيد دروسنا مرنة ومتنوعة، مصممة خصيصًا لتناسب جدولك اليومي دون أي تأثير سلبي، مما يوفر لك تجربة تعلم مريحة ومنسجمة مع نمط حياتك.</p>
      </div>
      <div class="sec2-card">
        <svg xmlns="http://www.w3.org/2000/svg" width="162" height="273" viewBox="0 0 162 263" fill="none">
          <path
            d="M0.62383 223.86C0.62383 221.833 1.25716 220.06 2.52383 218.54L93.3438 110.62C98.4105 104.793 102.211 99.0933 104.744 93.52C107.277 87.6933 108.544 81.4867 108.544 74.9C108.544 66.2867 106.01 59.1933 100.944 53.62C96.1305 48.0467 89.2905 45.26 80.4238 45.26C62.4372 45.26 52.3038 55.3933 50.0238 75.66C50.0238 78.1933 48.6305 79.2067 45.8438 78.7L4.04383 71.86C1.5105 71.3533 0.497162 69.8333 1.00383 67.3C1.76383 58.4333 4.04383 49.9467 7.84383 41.84C11.6438 33.7333 16.9638 26.64 23.8038 20.56C30.8972 14.48 39.2572 9.66664 48.8838 6.11998C58.7638 2.31998 69.9105 0.419983 82.3238 0.419983C94.7372 0.419983 105.757 2.31998 115.384 6.11998C125.264 9.91999 133.624 15.1133 140.464 21.7C147.304 28.2867 152.497 36.14 156.044 45.26C159.844 54.1267 161.744 63.7533 161.744 74.14C161.744 86.5533 159.337 97.8267 154.524 107.96C149.964 117.84 143.631 127.467 135.524 136.84L67.5038 217.02V217.78H157.944C160.477 217.78 161.744 219.047 161.744 221.58V259.2C161.744 261.733 160.477 263 157.944 263H4.42383C1.8905 263 0.62383 261.733 0.62383 259.2V223.86Z"
            fill="url(#paint0_linear_615_29282)"></path>
          <defs>
            <linearGradient id="paint0_linear_615_29282" x1="82" y1="-2" x2="82" y2="272"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#9C9C9C"></stop>
              <stop offset="1" stop-color="#F1F1F1"></stop>
            </linearGradient>
          </defs>
        </svg>
        <h1>أندية مونجلش التفاعلية</h1>
        <p>استعد لدخول عالم مونجلش الحيوي مع أندية مونجلش التفاعلية، حيث كل باب تفتحه يقودك إلى تجارب تعليمية مميزة وملهمة، تبرز مهاراتك في اللغة الإنجليزية وترتقي بأفقك الثقافي والمهني.</p>
      </div>
      <div class="sec2-card">
        <svg xmlns="http://www.w3.org/2000/svg" width="162" height="273" viewBox="0 0 162 253" fill="none">
          <path
            d="M1.64047 196.8C1.16047 194.4 2.12047 192.84 4.52047 192.12L44.1205 184.56C46.2805 184.32 47.7205 185.28 48.4405 187.44C50.6005 193.92 54.3205 199.44 59.6005 204C64.8805 208.32 71.8405 210.48 80.4805 210.48C89.8405 210.48 97.1605 207.48 102.44 201.48C107.96 195.48 110.72 187.92 110.72 178.8C110.72 167.76 107.6 159.24 101.36 153.24C95.3605 147.24 87.0805 144.24 76.5205 144.24H66.0805C63.6805 144.24 62.4805 143.04 62.4805 140.64V105.72C62.4805 103.32 63.6805 102.12 66.0805 102.12H75.8005C84.9205 102.12 92.2405 99.36 97.7605 93.84C103.28 88.32 106.04 80.88 106.04 71.52C106.04 62.88 103.52 55.92 98.4805 50.64C93.6805 45.36 86.9605 42.72 78.3205 42.72C70.6405 42.72 64.5205 44.76 59.9605 48.84C55.6405 52.92 52.5205 58.44 50.6005 65.4C49.8805 67.56 48.4405 68.4 46.2805 67.92L7.04047 60.36C4.64047 59.88 3.68047 58.56 4.16047 56.4C5.60047 48.24 8.36047 40.8 12.4405 34.08C16.5205 27.12 21.8005 21.12 28.2805 16.08C35.0005 11.04 42.6805 7.19999 51.3205 4.55999C60.2005 1.67999 70.0405 0.239984 80.8405 0.239984C92.8405 0.239984 103.52 1.91998 112.88 5.27998C122.24 8.63998 130.16 13.32 136.64 19.32C143.12 25.32 148.04 32.52 151.4 40.92C154.76 49.08 156.44 58.08 156.44 67.92C156.44 78.48 154.04 88.08 149.24 96.72C144.68 105.36 137.96 112.56 129.08 118.32V119.04C132.68 120.96 136.4 123.6 140.24 126.96C144.08 130.32 147.56 134.52 150.68 139.56C153.8 144.36 156.32 150 158.24 156.48C160.16 162.96 161.12 170.28 161.12 178.44C161.12 189.72 159.08 199.92 155 209.04C151.16 218.16 145.64 225.96 138.44 232.44C131.24 238.92 122.72 243.96 112.88 247.56C103.28 251.16 92.7205 252.96 81.2005 252.96C60.3205 252.96 42.6805 248.04 28.2805 238.2C13.8805 228.36 5.00047 214.56 1.64047 196.8Z"
            fill="url(#paint0_linear_615_29290)"></path>
          <defs>
            <linearGradient id="paint0_linear_615_29290" x1="82" y1="-6" x2="82" y2="268"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#9C9C9C"></stop>
              <stop offset="1" stop-color="#F1F1F1"></stop>
            </linearGradient>
          </defs>
        </svg>
        <h1>تقارير متابعة للأداء اليومي</h1>
        <p>أداة فعالة لرصد وتقييم التقدم المستمر. نعتبر هذه التقارير جزءاً لا يتجزأ من استراتيجيتنا التعليمية، حيث تسهم في توفير فهم عميق وشامل لأداء كل متعلم على نحو يومي. تساهم في تحديد النقاط التي يحتاج الطالب إلى تركيز جهود فيها.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MonglishSectionTwo",
};
</script>

<style scoped>
.kids-sec2 {
  direction: rtl;
  text-align: center;
  background-color: #fff;
  color: #165e84;
  font-family: 'DIN Next LT Arabic';
  font-weight: 500;
  padding: 2% 2% 1% 2%;
}
.kids-sec2 h1 {
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-size: 54px;
  font-weight: 500;
  line-height: 70.56px;
  color: #165e84;
}
.kids-sec2-cards {
  display: flex;
  justify-content: center;
  gap: 2em;
  padding: 6% 2% 0 2%;
  flex-wrap: wrap;
}
.sec2-card {
  border-radius: 1em;
  padding: 1em;
  flex: 1 1 15em; 
  max-width: 95%;
  height: 30em;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: rgba(104, 104, 104, 0.3) 1px solid;
  margin-bottom: 1em;
  background: linear-gradient(to bottom, #ffffff, #cccccc);
}
.sec2-card svg {
  width: 100%;
  height: 100%;
  margin-bottom: 3em;
  margin-top: -6em;
}
.sec2-card h1 {
  font-family: 'DIN Next LT Arabic';
  font-weight: 500;

  font-size: 2.3em;
}
.sec2-card p {
  font-size: 1.3em;
  color: #666;
  margin: 0 1em;
}
@media (max-width: 768px) {
  .kids-sec2-cards {
    padding: 1em;
  }
  .kids-space h1 {
    font-size: 2em;
  }
  .kids-space h3 {
    font-size: 1.6em;
  }
  .sec2-card {
    flex: 1 1 100%;
    max-width: 100%;
  }
  .sec2-card h1 {
    font-size: 1.5em;
  }
  .sec2-card p {
    font-size: 1em;
  }
}
@media (max-width: 480px) {
  .kids-space h1 {
    font-size: 1.5em;
  }
  .kids-space h3 {
    font-size: 1.3em;
  }
  .sec2-card svg {
    max-width: 60%;
    margin-top: 0;
    margin-bottom: 0;
  }
  .sec2-card h1 {
    font-size: 1.2em;
  }
  .sec2-card p {
    font-size: 0.9em;
  }
}
</style>