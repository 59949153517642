<template>
  <main class="section1">
    <div class="section1-content">
      <div class="text-content">
        <div class="welcome flex">
          <div>Welcome to the Official Website of Monglish Global Network (MGN)</div>
        </div>
        <h1 class="title">Learn English with Our Unique Immerse Approach</h1>
        <p class="subtitle">Monglish International Academy (UK, USA, Dubai, Uzbekistan and Egypt)</p>
        <ul class="circle-list">
          <li>
            <span class="check-mark">
              <i class="fa-solid fa-circle-check"></i>
            </span>
            Internationally certified
          </li>
          <li>
          <span class="check-mark">
              <i class="fa-solid fa-circle-check"></i>
            </span>
            Interactive learning methods
          </li>
          <li>
          <span class="check-mark">
              <i class="fa-solid fa-circle-check"></i>
            </span>
            Support 24/7
          </li>
        </ul>
        <div class="one-line">
          <MainButton @click="goToPackages()">English Courses</MainButton>
          <a href="/en/accredations">
            <button class="info-btn one-line">
              Explore
              <i class="fa-solid fa-arrow-right"></i>
            </button>
          </a>
        </div>
        <h1 class="title-secondary mt-3">Accreditations and Memberships</h1>
        <div class="accreditations-wrapper mt-2">
          <Carousel
              ref="carousel"
              :itemsToShow="8"
              :wrapAround="true"
              :mouseDrag="true"
              :autoplay="3000"
              :transition="500"
              :gap="20"
          >
            <Slide v-for="(accreditationImage, index) in accreditationImages" :key="index" class="carousel-slide">
              <img :src="accreditationImage.img" alt="Reason Image" class="reason-image" />
            </Slide>
          </Carousel>
        </div>
      </div>

      <div class="image-content hidden lg:block">
        <img src="@/assets/images/Desktop.webp" alt="Home Section 1 Image" />
      </div>
    </div>

    <div class="text-center mt-20">
      <h1 class="title-2">Success Partners</h1>
      <div class="companies">
        <img src="@/assets/images/companies/1.webp">
        <img src="@/assets/images/companies/2.webp">
        <img src="@/assets/images/companies/3.webp">
        <img src="@/assets/images/companies/4.webp">
        <img src="@/assets/images/companies/5.webp">
        <img src="@/assets/images/companies/6.webp">
        <img src="@/assets/images/companies/7.webp">
        <img src="@/assets/images/companies/8.webp">
      </div>
    </div>
  </main>
</template>

<script>
import MainButton from '../Elements/MainButton.vue';
import { Carousel, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
  name: 'ViewOne',
  components: {
    Carousel,
    Slide,
    MainButton
  },
  data() {
    return {
      accreditationImages: [
        { img: require('@/assets/images/accreditations/1.webp') },
        { img: require('@/assets/images/accreditations/2.webp') },
        { img: require('@/assets/images/accreditations/3.webp') },
        { img: require('@/assets/images/accreditations/4.webp') },
        { img: require('@/assets/images/accreditations/5.webp') },
        { img: require('@/assets/images/accreditations/6.webp') },
        { img: require('@/assets/images/accreditations/7.webp') },
        { img: require('@/assets/images/accreditations/8.webp') },
        { img: require('@/assets/images/accreditations/9.webp') },
        { img: require('@/assets/images/accreditations/10.webp')},
      ],
      currentStartIndex: 0,
      isTransitioning: false,
      transitionTime: 500,
    };
  },
  computed: {
    visibleAccreditations() {
      return [...this.accreditationImages, ...this.accreditationImages];
    },
    carouselStyle() {
      return {
        transform: `translateX(+${this.currentStartIndex * 95}px)`,
        transition: this.isTransitioning ? `transform ${this.transitionTime}ms ease-in-out` : 'none',
      };
    },
  },
  mounted() {
    setInterval(() => {
      this.moveCarousel();
    }, 3000);
  },
  methods: {
    moveCarousel() {
      this.isTransitioning = true;
      this.currentStartIndex += 1;
    },
    handleTransitionEnd() {
      this.isTransitioning = false;
      this.currentStartIndex = 0;

      const firstImage = this.accreditationImages.shift();
      this.accreditationImages.push(firstImage);
    },
    goToPackages(){
      this.$router.push({ name: 'PackagesViewEn' });
    }
  },
};
</script>


<style src="@/assets/styles/ar/ViewOne.css" scoped></style>