<template>
  <div class="section-two">
    <div class="title my-5">
      <div>خدماتنا تلبي كافة الاحتياجات !</div>
      <div>تقدم الأكاديمية دورات تعليمية وتدريبية متكاملة لجميع الراغبين في التعلم</div>
    </div>
    <div class="cards-container">
      <HomeCard
        :img-src="require('@/assets/images/2.webp')"
        title="الاستعداد لاختبار"
        subtitle="( IELTS )"
        description="تدريب شامل يغطي جميع مهارات الاختبار, مع دعم مخصص لضمان اجتيازك بثقة وتحقيق أعلى الدرجات!"
        @click="goToPackagesPage"
      >
        <template #button>
          <MainButton @click="goToPackagesPage">استكشف باقات التحضير</MainButton>
        </template>
      </HomeCard>
      <HomeCard
        :img-src="require('@/assets/images/3.webp')"
        title="تأسيس الإنجليزية من (5-18) سنه"
        subtitle="( دورات للصغار )"
        description="منهج المعايشة ينمي مهارات الإنجليزية للصغار والشباب عبر التفاعل اليومي والدعم المستمر للنجاح الشخصي والمهني."
        @click="goToKidsCoursesPage"
      >
        <template #button>
          <MainButton @click="goToKidsCoursesPage">استكشف دورات الأطفال</MainButton>
        </template>
      </HomeCard>
      <HomeCard
        :img-src="require('@/assets/images/1.webp')"
        title="تعليم الإنجليزية"
        subtitle="( دورات للكبار )"
        description="تحسين لغتك الإنجليزية وزيادة نجاحك بعملك عشر مرات من خلال التعلم عبر الإنترنت مع مدربين ذوي خبرة!"
        @click="goToAdultsCoursesPage"
      >
        <template #button>
          <MainButton @click="goToAdultsCoursesPage">استكشف دورات الكبار</MainButton>
        </template>
      </HomeCard>
    </div>
  </div>
</template>

<script>
import HomeCard from '../Elements/HomeCard.vue'; 
import MainButton from '../Elements/MainButton.vue'; 

export default {
  name: 'ViewTwo',
  components: {
    HomeCard,
    MainButton, 
  },
  methods: {
    goToKidsCoursesPage() {
      this.$router.push({ path: '/ar/kids-courses', hash: '#packages-section' });
    },
    goToAdultsCoursesPage() {
      this.$router.push({ path: '/ar/adult-courses', hash: '#packages-section' });
    },
    goToPackagesPage() {
      this.$router.push({ path: '/ar/ilets-courses', hash: '#packages-section' });
    }
  },
}
</script>

<style src="@/assets/styles/ar/ViewTwo.css" scoped></style>
