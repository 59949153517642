<template>
    <div class="job-container">
        <main class="job-main">
            <div class="job-card">
                <h2>التسجيل للوظيفة</h2>
                <form @submit.prevent="registerJob" class="card" enctype="multipart/form-data">
                    <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
                    <div class="form-row">
                        <div class="form-group">
                            <label for="job_apply">الوظيفة (مطلوب) <span class="error">*</span></label>
                            <select name="job_apply" id="job_apply" required="required" v-model="job_apply">
                                <option value="مدرس لغة إنجليزية كلغة ثانية">مدرس لغة إنجليزية كلغة ثانية</option>
                                <option value="مدرس قراءة وتعديل اللهجات">مدرس قراءة وتعديل اللهجات</option>
                                <option value="مدرب نادي التحدث">مدرب نادي التحدث</option>
                                <option value="مدرس كتابة">مدرس كتابة</option>
                                <option value="مستشار طلابي">مستشار طلابي</option>
                                <option value="محرر فيديو">محرر فيديو</option>
                                <option value="مشرف على وسائل التواصل الاجتماعي">مشرف على وسائل التواصل الاجتماعي</option>
                                <option value="مشتري وسائط">مشتري وسائط</option>
                                <option value="كاتب محتوى">كاتب محتوى</option>
                                <option value="مصمم جرافيكي">مصمم جرافيكي</option>
                                <option value="مصمم واجهة مستخدم/تجربة مستخدم">مصمم واجهة مستخدم/تجربة مستخدم</option>
                                <option value="منسق موارد بشرية">منسق موارد بشرية</option>
                                <option value="موظف شخصي ومسؤول عن الرواتب">موظف شخصي ومسؤول عن الرواتب</option>
                                <option value="أخصائي توظيف">أخصائي توظيف</option>
                                <option value="قائد فريق المبيعات">قائد فريق المبيعات</option>
                                <option value="مستشار تعليمي">مستشار تعليمي</option>
                                <option value="مشرف صف">مشرف صف</option>
                                <option value="وكيل قبول">وكيل قبول</option>
                                <option value="مكتب مساعدة">مكتب مساعدة</option>
                                <option value="مطور كامل لـ Laravel من PHP">مطور كامل لـ Laravel من PHP</option>
                                <option value="مهندس شبكات">مهندس شبكات</option>
                                <option value="موظف استقبال">موظف استقبال</option>
                                <option value="مسؤول مشتريات">مسؤول مشتريات</option>
                                <option value="محاسب">محاسب</option>
                                <option value="أخصائي جودة">أخصائي جودة</option>
                                <option value="أخصائي تدقيق داخلي">أخصائي تدقيق داخلي</option>
                            </select>
                            <p v-if="errors.job_apply" class="error">{{ errors.job_apply }}</p>
                        </div>
                        <div class="form-group">
                            <label for="experience">الخبرة (مطلوب)</label>
                            <input type="number" name="experience" id="experience" v-model="experience"
                                placeholder="عدد سنين الخبرة">
                            <p v-if="errors.experience" class="error">{{ errors.experience }}</p>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group">
                            <label for="fullname">الاسم كامل (مطلوب) <span class="error">*</span></label>
                            <input type="text" name="fullname" id="fullname" v-model="fullname"
                                placeholder="الاسم كامل">
                            <p v-if="errors.fullname" class="error">{{ errors.fullname }}</p>
                        </div>
                        <div class="form-group">
                            <label for="email">البريد الالكتروني (مطلوب) <span class="error">*</span></label>
                            <input type="text" name="email" id="email" v-model="email"
                                placeholder="example@example.com">
                            <p v-if="errors.email" class="error">{{ errors.email }}</p>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group">
                            <label for="phone">رقم الهاتف (مطلوب) <span class="error">*</span></label>
                            <vue-tel-input v-model="phone" :default-country="telInputOptions.defaultCountry"
                                :only-countries="telInputOptions.onlyCountries"
                                :preferred-countries="telInputOptions.preferredCountries"
                                :localized-countries="telInputOptions.localizedCountries" name="phone"
                                style="background-color: #f7f9fc" @input="handlePhoneInput"
                                placeholder="(201) 555-0123"></vue-tel-input>
                            <p v-if="errors.phone" class="error">{{ errors.phone }}</p>
                        </div>
                        <div class="form-group">
                            <label for="whatsappno">رقم واتساب (مطلوب) <span class="error">*</span></label>
                            <vue-tel-input v-model="whatsappno" :default-country="telInputOptions.defaultCountry"
                                :only-countries="telInputOptions.onlyCountries"
                                :preferred-countries="telInputOptions.preferredCountries"
                                :localized-countries="telInputOptions.localizedCountries" name="whatsapp_no"
                                style="background-color: #f7f9fc" @input="handleWhatsNoInput"
                                placeholder="(201) 555-0123"></vue-tel-input>
                            <p v-if="errors.whatsappno" class="error">{{ errors.whatsappno }}</p>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group">
                            <label for="cv">CV (مطلوب) <span class="error">*</span></label>
                            <input type="file" name="cv" id="cv" v-on:change="handleFileChange">
                        </div>
                    </div>
                    <!-- <div class="form-row">
                        <div class="form-group">
                            <label for="notes">ملحوظه</label>
                            <textarea name="note" id="note" rows="4" placeholder="مرحبا"></textarea>
                        </div>
                    </div> -->
                    <button type="submit" class="register-button" :disabled="isSubmitting">تسجيل الدخول</button>
                </form>
            </div>
        </main>
    </div>
</template>

<script>
    import { ref } from 'vue';
    import axios from 'axios';
    import { useRouter } from 'vue-router';
    import { useStore } from 'vuex';
    import { VueTelInput } from 'vue-tel-input';
    import 'vue-tel-input/vue-tel-input.css';

    const localizedCountries = {
        'af': 'Afghanistan',
        'al': 'Albania',
        'dz': 'Algeria',
        'as': 'American Samoa',
        'ad': 'Andorra',
        'ao': 'Angola',
        'ai': 'Anguilla',
        'aq': 'Antarctica',
        'ag': 'Antigua and Barbuda',
        'ar': 'Argentina',
        'am': 'Armenia',
        'aw': 'Aruba',
        'au': 'Australia',
        'at': 'Austria',
        'az': 'Azerbaijan',
        'bs': 'Bahamas',
        'bh': 'Bahrain',
        'bd': 'Bangladesh',
        'bb': 'Barbados',
        'by': 'Belarus',
        'be': 'Belgium',
        'bz': 'Belize',
        'bj': 'Benin',
        'bm': 'Bermuda',
        'bt': 'Bhutan',
        'bo': 'Bolivia',
        'ba': 'Bosnia and Herzegovina',
        'bw': 'Botswana',
        'br': 'Brazil',
        'bn': 'Brunei',
        'bg': 'Bulgaria',
        'bf': 'Burkina Faso',
        'bi': 'Burundi',
        'cv': 'Cabo Verde',
        'kh': 'Cambodia',
        'cm': 'Cameroon',
        'ca': 'Canada',
        'ky': 'Cayman Islands',
        'cf': 'Central African Republic',
        'td': 'Chad',
        'cl': 'Chile',
        'cn': 'China',
        'co': 'Colombia',
        'km': 'Comoros',
        'cg': 'Congo',
        'cd': 'Congo, Democratic Republic of the',
        'ck': 'Cook Islands',
        'cr': 'Costa Rica',
        'ci': 'Côte d’Ivoire',
        'hr': 'Croatia',
        'cu': 'Cuba',
        'cy': 'Cyprus',
        'cz': 'Czech Republic',
        'dk': 'Denmark',
        'dj': 'Djibouti',
        'dm': 'Dominica',
        'do': 'Dominican Republic',
        'ec': 'Ecuador',
        'eg': 'Egypt',
        'sv': 'El Salvador',
        'gq': 'Equatorial Guinea',
        'er': 'Eritrea',
        'ee': 'Estonia',
        'sz': 'Eswatini',
        'et': 'Ethiopia',
        'fk': 'Falkland Islands',
        'fo': 'Faroe Islands',
        'fj': 'Fiji',
        'fi': 'Finland',
        'fr': 'France',
        'gf': 'French Guiana',
        'pf': 'French Polynesia',
        'ga': 'Gabon',
        'gm': 'Gambia',
        'ge': 'Georgia',
        'de': 'Germany',
        'gh': 'Ghana',
        'gi': 'Gibraltar',
        'gr': 'Greece',
        'gl': 'Greenland',
        'gd': 'Grenada',
        'gp': 'Guadeloupe',
        'gu': 'Guam',
        'gt': 'Guatemala',
        'gg': 'Guernsey',
        'gn': 'Guinea',
        'gw': 'Guinea-Bissau',
        'gy': 'Guyana',
        'ht': 'Haiti',
        'hn': 'Honduras',
        'hk': 'Hong Kong',
        'hu': 'Hungary',
        'is': 'Iceland',
        'in': 'India',
        'id': 'Indonesia',
        'ir': 'Iran',
        'iq': 'Iraq',
        'ie': 'Ireland',
        'im': 'Isle of Man',
        'il': 'Israel',
        'it': 'Italy',
        'jm': 'Jamaica',
        'jp': 'Japan',
        'je': 'Jersey',
        'jo': 'Jordan',
        'kz': 'Kazakhstan',
        'ke': 'Kenya',
        'ki': 'Kiribati',
        'kp': 'Korea, North',
        'kr': 'Korea, South',
        'kw': 'Kuwait',
        'kg': 'Kyrgyzstan',
        'la': 'Laos',
        'lv': 'Latvia',
        'lb': 'Lebanon',
        'ls': 'Lesotho',
        'lr': 'Liberia',
        'ly': 'Libya',
        'li': 'Liechtenstein',
        'lt': 'Lithuania',
        'lu': 'Luxembourg',
        'mo': 'Macau',
        'mg': 'Madagascar',
        'mw': 'Malawi',
        'my': 'Malaysia',
        'mv': 'Maldives',
        'ml': 'Mali',
        'mt': 'Malta',
        'mh': 'Marshall Islands',
        'mq': 'Martinique',
        'mr': 'Mauritania',
        'mu': 'Mauritius',
        'yt': 'Mayotte',
        'mx': 'Mexico',
        'fm': 'Micronesia',
        'md': 'Moldova',
        'mc': 'Monaco',
        'mn': 'Mongolia',
        'me': 'Montenegro',
        'ms': 'Montserrat',
        'ma': 'Morocco',
        'mz': 'Mozambique',
        'mm': 'Myanmar',
        'na': 'Namibia',
        'nr': 'Nauru',
        'np': 'Nepal',
        'nl': 'Netherlands',
        'nc': 'New Caledonia',
        'nz': 'New Zealand',
        'ni': 'Nicaragua',
        'ne': 'Niger',
        'ng': 'Nigeria',
        'nu': 'Niue',
        'nf': 'Norfolk Island',
        'mk': 'North Macedonia',
        'mp': 'Northern Mariana Islands',
        'no': 'Norway',
        'om': 'Oman',
        'pk': 'Pakistan',
        'pw': 'Palau',
        'ps': 'Palestine',
        'pa': 'Panama',
        'pg': 'Papua New Guinea',
        'py': 'Paraguay',
        'pe': 'Peru',
        'ph': 'Philippines',
        'pl': 'Poland',
        'pt': 'Portugal',
        'pr': 'Puerto Rico',
        'qa': 'Qatar',
        're': 'Réunion',
        'ro': 'Romania',
        'ru': 'Russia',
        'rw': 'Rwanda',
        'bl': 'Saint Barthélemy',
        'sh': 'Saint Helena',
        'kn': 'Saint Kitts and Nevis',
        'lc': 'Saint Lucia',
        'mf': 'Saint Martin',
        'pm': 'Saint Pierre and Miquelon',
        'vc': 'Saint Vincent and the Grenadines',
        'ws': 'Samoa',
        'sm': 'San Marino',
        'st': 'São Tomé and Príncipe',
        'sa': 'Saudi Arabia',
        'sn': 'Senegal',
        'rs': 'Serbia',
        'sc': 'Seychelles',
        'sl': 'Sierra Leone',
        'sg': 'Singapore',
        'sx': 'Sint Maarten',
        'sk': 'Slovakia',
        'si': 'Slovenia',
        'sb': 'Solomon Islands',
        'so': 'Somalia',
        'za': 'South Africa',
        'ss': 'South Sudan',
        'es': 'Spain',
        'lk': 'Sri Lanka',
        'sd': 'Sudan',
        'sr': 'Suriname',
        'se': 'Sweden',
        'ch': 'Switzerland',
        'sy': 'Syria',
        'tw': 'Taiwan',
        'tj': 'Tajikistan',
        'tz': 'Tanzania',
        'th': 'Thailand',
        'tl': 'Timor-Leste',
        'tg': 'Togo',
        'tk': 'Tokelau',
        'to': 'Tonga',
        'tt': 'Trinidad and Tobago',
        'tn': 'Tunisia',
        'tr': 'Turkey',
        'tm': 'Turkmenistan',
        'tc': 'Turks and Caicos Islands',
        'tv': 'Tuvalu',
        'ug': 'Uganda',
        'ua': 'Ukraine',
        'ae': 'United Arab Emirates',
        'gb': 'United Kingdom',
        'us': 'United States',
        'uy': 'Uruguay',
        'uz': 'Uzbekistan',
        'vu': 'Vanuatu',
        'va': 'Vatican City',
        've': 'Venezuela',
        'vn': 'Vietnam',
        'vg': 'Virgin Islands, British',
        'vi': 'Virgin Islands, U.S.',
        'eh': 'Western Sahara',
        'ye': 'Yemen',
        'zm': 'Zambia',
        'zw': 'Zimbabwe'
    };

    export default {
        components: {
            VueTelInput,
        },
        setup() {
            const router = useRouter();
            const store = useStore();
            const job_apply = ref('');
            const experience = ref('');
            const fullname = ref('');
            const email = ref('');
            const phone = ref('');
            const whatsappno = ref('');
            const cv = ref(null);
            const notes = ref('');
            const errors = ref({ job_apply: '', experience: null, fullname: '', email: '', phone: '', whatsappno: '', cv: '' });
            const errorMessage = ref('');
            const isSubmitting = ref(false);

            const telInputOptions = {
                onlyCountries: Object.keys(localizedCountries),
                preferredCountries: ['us'],
                localizedCountries: localizedCountries,
                defaultCountry: 'us',
            };

            const handleFileChange = (event) => {
                cv.value = event.target.files[0];
            };

            const validate = () => {
                errors.value.job_apply == job_apply.value.trim() === '' ? 'الوظيفه مطلوبه' : '';
                errors.value.experience == experience.value === null ? 'الخبرة مطلوبه' : '';
                errors.value.fullname == fullname.value.trim() === '' ? 'الاسم مطلوبه' : '';
                errors.value.email = email.value.trim() === '' ? 'البريد الإلكتروني مطلوب' : '';
                errors.value.phone = phone.value.trim() === '' ? 'الهاتف مطلوب' : '';
                errors.value.whatsappno = whatsappno.value.trim() === '' ? 'رقم الواتساب مطلوب' : '';
                return !errors.value.job_apply && !errors.value.experience && !errors.value.fullname && !errors.value.email && !errors.value.phone && !errors.value.whatsappno;
            };

            const registerJob = async () => {
                if (!validate()) return;

                isSubmitting.value = true;
                errorMessage.value = '';

                try {
                    const formData = new FormData();
                    formData.append('position', job_apply.value);
                    formData.append('experience', experience.value);
                    formData.append('name', fullname.value);
                    formData.append('email', email.value);
                    formData.append('phone', phone.value);
                    formData.append('whatsapp_no', whatsappno.value);
                    formData.append('cv', cv.value); // Append the file
                    formData.append('notes', notes.value);

                    const response = await axios.post('/api/job-application-forms', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    const userInfo = response.data.data;

                    // Update the Vuex store
                    store.dispatch('updateUser', userInfo);

                    // Store the token and user name in sessionStorage
                    sessionStorage.setItem('userInfo', JSON.stringify(userInfo));

                    // Redirect to another page, e.g., home page
                    router.push({ name: 'HomeAr' }); // Assuming you have a route named 'Home Page'
                } catch (error) {
                    console.log(error);
                    errorMessage.value = error.response ? error.response.data.data.error : error;
                } finally {
                    isSubmitting.value = false;
                }
            };
            return {
                job_apply,
                experience,
                fullname,
                email,
                phone,
                whatsappno,
                cv,
                notes,
                errors,
                errorMessage,
                isSubmitting,
                registerJob,
                telInputOptions,
                handleFileChange
            };
        },
        methods: {
            handlePhoneInput(phone) {
                console.log('Selected Phone Number:', phone);
            },
            handleWhatsNoInput(phone) {
                console.log('Selected Phone Number:', phone);
            },
        },
    }
</script>

<style scoped>
    /* job container */
    .job-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1000px;
        margin-top: -100px;
        background-image: url('@/assets/images/job_registration_bg.png');
        background-size: cover;
        background-position: center;
        color: #464646;
    }

    /* job main */
    .job-main {
        display: flex;
        width: 80%;
        justify-content: space-between;
        align-items: center;
    }

    .job-card {
        flex: 1;
        background: #fff;
        padding: 80px 40px;
        border-radius: 20px;
        box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
        text-align: center;
        margin-top: 50px;
    }

    .job-card h2 {
        color: #000;
        font-family: "DIN Next LT Arabic", Sans-serif;
        margin-bottom: 20px;
        text-align: center;
        font-size: 2.5rem;
        font-weight: 500;
    }

    .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .form-group {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .form-group label {
        margin-top: 15px;
        text-align: right;
        margin-right: 30px;
        color: #8D93A1;
    }

    .form-group input,
    .form-group select,
    .form-group textarea {
        width: 90%;
        padding: 10px;
        margin-right: 20px;
        border-radius: 10px;
        background-color: #f7f9fc;
        text-align: right;
    }

    .form-group input[type='file'] {
        background-color: transparent;
    }

    .form-group .vue-tel-input {
        width: 90%;
        background-color: #f7f9fc;
        margin-inline: 1.5rem;
    }

    .form-group .vue-tel-input input[type="tel"] {
        background-color: #f7f9fc;
    }

    .vue-tel-input :deep(.vti__dropdown.open .vti__dropdown-list.below) {
        right: 0;
        width: 39.4rem;
        text-align: right;
    }

    .vue-tel-input :deep(.vti__input) {
        text-align: right;
    }

    .register-button {
        padding: 5px;
        background: linear-gradient(45deg, #fe9b4f, #f47e23);
        color: white;
        border: none;
        border-radius: 20px;
        width: 100%;
        cursor: pointer;
        margin-top: 30px;
        font-size: larger;
    }

    .form-row {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .terms {
        margin-top: 25px;
    }

    .error {
        color: red;
    }

    @media (min-width: 320px) and (max-width: 479px) {
        .job-container {
            height: max-content;
        }

        .job-main {
            flex-direction: column;
            margin: auto;
            width: 90%;
        }

        .job-container .job-info-p {
            color: #fff;
            font-size: medium;
            text-align: center;
        }

        .job-container .job-card {
            margin-top: 110px;
            width: 100%;
            border-radius: 20px;
            padding: 40px 40px;
        }

        .job-card h2 {
            font-size: larger;
        }

        .form-row {
            flex-direction: column;
        }

        .form-group {
            width: 100%;
        }

        .form-group label {
            margin-right: 0;
            margin-top: 0;
        }

        .form-group input, .form-group select, .form-group textarea {
            width: 100%;
            /* padding: 5px; */
            margin: 0;
        }

        .form-group .vue-tel-input {
            margin-inline: 0;
        }

        .vue-tel-input :deep(.vti__dropdown.open .vti__dropdown-list.below) {
            right: 0;
            width: 17.2rem;
            text-align: right;
        }

        .vue-tel-input :deep(.vti__input) {
            text-align: right;
        }

        .job-button {
            padding: 5px;
            font-size: medium;
            width: 80%;
        }
    }

    @media (min-width: 480px) and (max-width: 639px) {

        /* Styles */
        .job-container .job-info-p {
            color: #fff;
        }

        .job-container .job-card {
            margin-top: 40px;
        }

        .job-main {
            flex-direction: column;
        }

    }
</style>