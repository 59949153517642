<template>
  <div class="card-2">
    <img :src="imgSrc" alt="Card Image" />
    <div class="titles-container"> 
      <div class="t1">{{ title }}</div>
      <div class="t2">{{ subtitle }}</div>
      <div class="t3">{{ description }}</div>
    </div>
    <slot name="button" />
  </div>
</template>

<script>
export default {
  name: 'HomeCard',
  props: {
    imgSrc: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
}
</script>

<style src="@/assets/styles/ar/ViewTwo.css" scoped></style>