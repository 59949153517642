<template>
  <div class="monglish-reasons">
    <div class="reasons-text">
      <h1>تميزكم في تعلم الإنجليزية يبدأ هنا</h1>
      <p>اكتشفوا التعليم الإلكتروني المتطور في أكاديمية مونجلش الدولية، حيث نقدم تجربة تدريبية غنية ومتعددة الوسائط عبر الأجهزة الذكية، تتيح تفاعلاً ديناميكيًا مع المدربين والزملاء لتطوير المهارات بمرونة، مع الوصول إلى دوراتنا في أي وقت ومن أي مكان، وفرصة التعلم مع متخصصين عالميين، لتجربة تعليمية فريدة ومتميزة ١٠٠٪ عبر الإنترنت.</p>
    </div>

    <div class="reasons-card">
      <h3>ساعد و طور طفلك !</h3>
      <p>ليصل لما يطمح له في مستقبله</p>
    </div>
  </div>

  <div class="vision-sec3">
    <h1>الريادة في التعليم الإلكتروني جودة، تفاعل، وإتقان</h1>
    <div class="vision-container">
      <iframe 
        src="https://player.vimeo.com/video/818262933" 
        frameborder="0" 
        allow="autoplay; fullscreen" 
        allowfullscreen>
      </iframe>
    </div>
  </div>

  <HomeReasons />
  <HomeCounter />

  <!--Section Four Content-->
  <div class="kids-sec4">
    <h3>معتمدون دوليا</h3>
    <h1>والجودة العالية في كل خطوة</h1>
    <p>أكاديمية مونجلش الدولية معتمدة دولياً من مؤسسات بارزة مثل المنظمة الدولية لجودة التدريب (IOTQ) وحاصلة على شهادة ISO 9001:2015 من الاتحاد الأمريكي للاعتماد (UAF). نحن أيضاً أعضاء في الجمعية الأمريكية للاعتماد (AAA)، الجمعية المصرية للجودة (ESQ)، ومنظمة الجودة العربية (AROQA) تحت مظلة طلال أبو غزالة (TAG-ORG)، مما يعكس التزامنا بالمعايير العالمية للتعليم العالي.</p>
    <div class="kids-sec4-imgs">
        <img src="@/assets/images/accreditations/6.webp"/>
        <img src="@/assets/images/accreditations/3.webp"/>
        <img src="@/assets/images/accreditations/2.webp"/>
        <img src="@/assets/images/accreditations/1.webp"/>
        <img src="@/assets/images/accreditations/4.webp"/>
        <img src="@/assets/images/accreditations/7.webp"/>
        <img src="@/assets/images/accreditations/5.webp"/>
        <img src="@/assets/images/accreditations/9.webp"/>
    </div>    
  </div>

  <div class="vision-sec3">
    <h1>المعايشة التامة</h1>
    <div class="vision-container">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/_NO8PSCuf_c?si=Zx92Nr0DAy8MmBUl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </div>
  </div>
</template>

<script>
import HomeCounter from '../../components/ar/HomeCounter.vue';
import HomeReasons from '../../components/ar/HomeReasons.vue';

export default {
  name: 'HomePage',
  components: {
    HomeCounter,
    HomeReasons
  },
}
</script>

<style scoped>
.monglish-reasons {
  background-image: url('@/assets/images/reason_bg.jpg');
  background-size: cover;
  background-position: center;
  height: 110vh;
  margin-top: -85px;
  padding: 12% 1% 1% 1%;
  font-family: 'DIN Next LT Arabic';
  box-sizing: border-box;
}
.reasons-text {
  color: white;
  text-align: right;
  padding: 1em;
  padding-left: 40%;
}
.reasons-text h1 {
  margin-top: 150px;
  font-size: 64px;
  font-weight: 500;
  line-height: 94.08px;
  font-family: 'DIN Next LT Arabic';
}
.reasons-text p {
  font-size: 22px;
  line-height: 32.34px;
}
.reasons-card {
  background-color: white;
  margin-top: 1%;
  padding: 1em 2em; 
  box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  text-align: right;
  width: 30%; 
}
.reasons-card h3 {
  color: #535353;
  font-size: 28px;
  font-weight: bold;
}
.reasons-card p {
  color: #535353;
  font-size: 28px;
}
@media (max-width: 768px) {
  .monglish-reasons {
    height: auto;
  }
  .reasons-text{
    text-align: center;
    max-width: 100%;
    padding-left: 0;
    padding-top: 20%;
  }
  .reasons-text h1 {
    margin-top: 20px;
    font-size: 2em;
    line-height: 50px;
  }
  .reasons-text p{
    font-size: 1em;
    line-height: 25px;
  }
  .reasons-card{
    width: 100%;
    margin: 10px auto;
    padding: 20px;
    text-align: center;
  }
  .reasons-card h3{
    font-size: 1.5em;
  }
  .reasons-card p{
    font-size: 1em;
  }
}
@media (max-width: 480px) {
  .reasons-text h1{
    font-size: 1.5em;
  }
  .reasons-text p{
    font-size: 0.9em;
  }
  .reasons-card h3{
    font-size: 1.3em;
  }
  .reasons-card p{
    font-size: 0.9em;
  }
}

.vision-sec3 {
  background-color: #fff;
  text-align: center;
  color: #165e84;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  width: 100%;
  position: relative;
  align-items: center;
  overflow: hidden;
  font-family: 'DIN Next LT Arabic', sans-serif;
  padding: 2em 1em;
  direction: ltr;
}
.vision-sec3 h1 {
  font-size: 2.8em;
  font-weight: 500;
  margin: 0 0 20px 0;
}
.vision-container {
  position: relative;
  width: 80%;
  height: 0;
  padding-bottom: 45%;
  margin-bottom: 1em;
  border-radius: 2em;
}
.vision-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2em;
}
@media (max-width: 1200px) {
  .vision-sec3 h1 {
    font-size: 2.2em;
  }
  .vision-container {
    width: 90%;
    padding-bottom: 50%;
  }
}
@media (max-width: 768px) {
  .vision-sec3 h1 {
    font-size: 28px;
  }
  .vision-container {
    width: 95%;
    padding-bottom: 56.25%; 
  }
}

/* Section 5 style */
.kids-sec4{
  background-image: url('@/assets/images/bg.jpg');
  background-size: cover;
  background-position: center;
  direction: rtl;
  text-align: center;
  color: #fff;
  font-family: 'DIN Next LT Arabic';
  font-weight: 500;
  padding: 2em 2em 8em 2em;
}
.kids-sec4 h3{
  font-size: 2.5rem;
  padding: 3% 0 0 0;
}
.kids-sec4 h1{
  font-size: 2.8rem;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
}
.kids-sec4 p{
  font-size: 1.2rem;
  padding: 1% 20% 5% 20%;
}
.kids-sec4-imgs{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2em;
}
.kids-sec4-imgs img{
    width: 9%;
}
@media (max-width: 1200px) {
  .kids-sec4 h3 {
    font-size: 2.2rem;
  }
  .kids-sec4 h1 {
    font-size: 2.5rem;
  }
  .kids-sec4 p {
    font-size: 1.1rem;
    padding: 1% 15% 5% 15%;
  }
  .kids-sec4-imgs img {
    width: 12%;
  }
}
@media (max-width: 992px) {
  .kids-sec4 h3 {
    font-size: 2rem;
  }
  .kids-sec4 h1 {
    font-size: 2.2rem;
  }
  .kids-sec4 p {
    font-size: 1rem;
    padding: 1% 10% 5% 10%;
  }
  .kids-sec4-imgs img {
    width: 15%;
  }
}
@media (max-width: 768px) {
  .kids-sec4 h3 {
    font-size: 1.8rem;
  }
  .kids-sec4 h1 {
    font-size: 2rem;
  }
  .kids-sec4 p {
    font-size: 0.9rem;
    padding: 1% 5% 5% 5%;
  }
  .kids-sec4-imgs img {
    width: 20%;
  }
}
@media (max-width: 576px) {
  .kids-sec4 h3 {
    font-size: 1.5rem;
  }
  .kids-sec4 h1 {
    font-size: 1.8rem;
  }
  .kids-sec4 p {
    font-size: 0.85rem;
    padding: 1% 3% 5% 3%;
  }
  .kids-sec4-imgs img {
    width: 25%;
  }
}
@media (max-width: 400px) {
  .kids-sec4 h3 {
    font-size: 1.2rem;
  }
  .kids-sec4 h1 {
    font-size: 1.5rem;
  }
  .kids-sec4 p {
    font-size: 0.75rem;
    padding: 1% 2% 5% 2%;
  }
  .kids-sec4-imgs img {
    width: 30%;
  }
}
</style>