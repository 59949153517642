<template>
  <div class="home-section-12">
    <div class="home-counter">
       <h2>تفوق ملموس <br>بأرقام لا تضاهى</h2>
       <p>اكتشفوا كيف تصنع أكاديمية مونجليش الدولية التميز في كل رقم من إحصائياتنا الباهرة</p>
    </div>
    <div class="counters">
      <div class="counter-home" v-for="(counter, index) in counters" :key="index" ref="counters">
        <div class="number-home">{{ formattedNumber(counter.current) }}</div>
        <div class="label-home">{{ counter.label }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VisionTwo',
  data() {
    return {
      counters: [
        { label: 'الجلسات المنتهية', end: 2441506, current: 0, isVisible: false },
        { label: 'خبراء تعليميون', end: 240, current: 0, isVisible: false },
        { label: 'المتدربون المسجلون', end: 18850, current: 0, isVisible: false },
        { label: 'معدل الرضا', end: 98.4, current: 0, isVisible: false },
      ],
    };
  },
  mounted() {
    this.observeCounters();
  },
  methods: {
    observeCounters() {
      const options = {
        root: null,
        threshold: 0.1,
      };
      const observer = new IntersectionObserver(this.handleIntersect, options);
      this.$refs.counters.forEach(counter => {
        observer.observe(counter);
      });
    },
    handleIntersect(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const index = this.$refs.counters.indexOf(entry.target);
          if (!this.counters[index].isVisible) {
            this.counters[index].isVisible = true;
            this.animateValue(this.counters[index], 0, this.counters[index].end, 3000);
          }
        }
      });
    },
    animateValue(counter, start, end, duration) {
      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        counter.current = Math.floor(progress * (end - start) + start);
        if (progress < 1) {
          window.requestAnimationFrame(step);
        } else {
          counter.current = end;
        }
      };
      window.requestAnimationFrame(step);
    },
    formattedNumber(value) {
      if (value === 98.4) {
        return `%${value.toFixed(1)}`;
      } else if (value === 240) {
        return `+${value}`;
      } else {
        return value.toLocaleString();
      }
    },
  },
};
</script>

<style scoped>

.home-section-12 {
  display: flex;
  height: auto;
  color: #165e84;
  padding-inline: 100px;
  padding-block: 100px;
  background-color: white;
  font-family: 'DIN Next LT Arabic', sans-serif;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}
.home-counter {
    max-width: 30%;
}
.home-counter h2 {
    font-size: 48px;
    line-height: 70px;
    font-weight: 500;
}
.home-counter p {
    color: #000000;
    font-size: 18px;
    margin-top: 20px;
}
.counters {
  display: flex;
}
.counter-home {
  border-radius: 15px;
  padding: 20px;
  text-align: center; 
  margin-inline: 15px;
  margin-top: 20px;
  gap: 20px;
}
.counter-home span:first-child {
  display: block;
  font-size: 36px; 
  font-weight: 500;
}
.number-home {
  font-size: 40px;
  font-weight: 900;
}
.label-home {
  font-size: 18px;
  color: #000000;
}
@media (max-width: 1000px) {
  .home-section-12 {
    flex-direction: column; 
    align-items: center;
    padding-inline: 10px;
    padding-block: 50px;
  }
  .home-counter {
    margin: 0 auto;
    max-width: 90%;
    text-align: center;
  }
  .counter-home{
      padding: 10px;
  margin-top: 10px;
  }
  .counters {
    flex-direction: column-reverse;
  }
  .home-counter h2 {
    font-size: 35px;
    line-height: 50px;
  }
  .home-counter p {
    font-size: 18px;
    margin-top: 20px;
  }
  .number-home {
  font-size: 28px;
}
.label-home {
  font-size: 18px;
}
}

</style>
