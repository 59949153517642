<template>
<div class="our-founder-sec1">
  <div class="founder-content">
    <h1>دكتور محمد عبدالوهاب شلبي</h1>
    <p>
      المؤسس ورئيس مجلس الإدارة لأكاديمية مونجلش الدولية ومجموعة إيديوليكسا العالمية | الشريك المؤسس لمدرسة ينابيع الدولية عبر الإنترنت
      <br>
      <br>
      الدكتور محمد عبدالوهاب شلبي هو شخصية قيادية رائدة في مجال التعليم الرقمي وتدريس اللغة الإنجليزية، ويمثل نموذجًا استثنائيًا للريادة الفكرية وريادة الأعمال في الوطن العربي. بفضل أكثر من 12 عاماً من الخبرة في تدريس اللغة الإنجليزية في جامعات ومدارس دولية بالسعودية وماليزيا والعديد من دول شرق آسيا، أثبت الدكتور شلبي مكانته كأحد رواد التعليم الإلكتروني في الوطن العربي، بخبرة كبيرة في تأسيس المدارس الإلكترونية وأنظمة التعليم الإلكتروني.
    </p>
    <div class="white-rectangle">
      دكتور محمد شلبي ليس فقط مؤسسًا وقائدًا تعليميًا، ولكنه أيضًا أب، معلم، رائد، دارس، ومدير ناجح، وقائد ماهر في فن الإدارة، يجمع بين العلم والخبرة والابتكار، ويسعى دائمًا لتحقيق التميز في كل ما يقدمه.
    </div>
  </div>
</div>

<div class="our-founder-sec2">
  <div class="founder-image">
    <img src="@/assets/images/our-founder2.jpg" alt="Our Founder Image">
  </div>
  <div class="founder-content-sec2">
    <h1>نشيط بكل حماس</h1>
    <p>
        بدأت الرحلة بتأسيسه ولعبه دور الرئيس التنفيذي لأكاديمية مونجلش للتدريب والتطوير (مصر) في العام 2019، وتزامن
        ذلك مع بدايات انتشار الكوفيد-19. كانت أكاديمية مونجلش هي الحل عبر الإنترنت لمشكلة كائنة على أرض الواقع. وقبل
        ذلك التاريخ كان قد ساهم، كمؤسس مشارك، في إنشاء مدرسة الينابيع الدولية عبر الإنترنت (مصر). <br> مع النجاحات
        المتتالية ونمو الطلب المتزايد لدورات أكاديمية مونجلش، قام د. شلبي بتأسيس أكاديمية مونجلش المملكة المتحدة
        (إنجلترا) وأصبح الرئيس التنفيذي لها، ثم توسّع لاحقًا واتخذ نفس المنصب كمؤسس لأكاديمية مونجلش الدولية
        (الولايات المتحدة الأمريكية). أصبحت تلك المؤسسات الدولية نواة أكاديمية مونجلش العالمية (Monglish Global Network
        -MGN)، والتي تمتد حاليًا لتحتل 6 مكاتب في 4 قارات في مُدن لندن، نيويورك، الإسكندرية، القاهرة، ومكتبين تمثيل
        في الرياض ودبي. بصفته خبيرًا في تدريس اللغات الأجنبية، ومبتكرًا لمنهج تعليم اللغات بالمعايشة، فقد قام
        بتسجيل منهجه الحصري في مكتبة الكونجرس (الولايات المتحدة الأمريكية).
    </p>
  </div>
</div>

<div class="our-founder-sec3">
  <div class="founder-text">
    <h1>صاحب <br> خبرة عريضة</h1>
    <p>
      يتمتع د. شلبي بخبرة دولية واسعة في التعلم الإلكتروني وتدريس اللغة الإنجليزية كلغة ثانية (ESL) في كيانات وأكاديميات مرموقة في دول الخليج وماليزيا ، بصفته مدرسًا معتمدًا وممتحنًا من جامعة كامبريدج (إنجلترا).
      تخرج الدكتور شلبي من كلية التربية (قسم اللغة الإنجليزية) بجامعة طنطا ، وحضر دراسات عليا في تدريس اللغة الإنجليزية في جامعة القاهرة و UPSI Malaysia. يقوم حاليًا بإتمام دراسته لدكتوراه إدارة الأعمال DBA في College de Paris (فرنسا).
      وهو حائز على شهادة الـ CELTA لتدريس اللغة الإنجليزية، وشهادة الـ TKT لمهارات التدريس، وشهادة الاعتماد كـ Speaking Examiner، وهذه المؤهلات الثلاثة من جامعة كامبردج المرموقة.
      شملت خبراته، كمحاضر لتدريس اللغة الإنجليزية، الأدوار التالية على مدار قرابة العشر سنوات:
    </p>
  </div>
  <div class="founder-images">
    <img src="@/assets/images/founder/1.png" alt="Image 1">
    <img src="@/assets/images/founder/2.png" alt="Image 2">
    <img src="@/assets/images/founder/3.png" alt="Image 3">
    <img src="@/assets/images/founder/5.png" alt="Image 5">
    <img src="@/assets/images/founder/4.png" alt="Image 4">
  </div>
</div>

<div class="home-sec4">
      <div class="t1">رصيد من الإنجازات</div>
        <button class="slider-nav left" @click="slide('left')">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="size-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
          </svg>
        </button>
      <div class="video-slider">
        <div class="video-wrapper-immerse" ref="videoWrapper">
          <iframe v-for="(video, index) in displayedVideos" :key="index" :src="video.src" frameborder="0" allow="autoplay; fullscreen" allowfullscreen class="video-frame-home">
          </iframe>
        </div>
      </div>
        <button class="slider-nav right" @click="slide('right')">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="size-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
          </svg>
        </button>
</div>

  <div class="our-founder-sec5">
    <div class="founder-info">
      <h1>متعلّم دائم</h1>
      <p>
        كونه مؤمنًا بحكمة العمل الجماعي والمعرفة الجماعية ، فإن د. شلبي عضو محترف في أكثر من عشر منظمات وجمعيات متعددة الجنسيات في مجالات التعليم وتعليم اللغة الإنجليزية للمتحدثين بلغات أخرى والجودة والإدارة.
      </p>
      <ul>
        <li>.عضو محترف بالاتحاد الأوروبي لاختبارات وتقييم اللغات EALTA (إنجلترا)</li>
        <li>.عضو مهني بالاتحاد الأمريكي للإدارة AMA (أمريكا)</li>
        <li>.عضو مهني بالمركز الأوروبي للإدارة MCE (بلجيكا)</li>
        <li>.عضو مهني بمنظمة المعلمين The Learning Guild (أمريكا)</li>
        <li>.عضو محترف بجمعية إدارة الأعمال العربية ARABMA (مصر)</li>
        <li>.عضو محترف بالاتحاد المصري للجودة ESQ (مصر)</li>
        <li>.عضو محترف بالمنظمة العربية لجودة التعليم AROQA (بلجيكا والأردن)</li>
        <li>.عضو محترف باتحاد التيسول الدولي للإنجليزية TESOL International (أمريكا)</li>
        <li>.عضو مهني بالاتحاد الدولي لاختبارات اللغات ILTA (أمريكا)</li>
        <li>.عضو مهني باتحاد التعليم عن بعد والتعليم المستقل ADEIL (أمريكا)</li>
        <li>.عضو محترف باتحاد الامتحانات اللغوية بأوروبا ALTE (إنجلترا)</li>
      </ul>
    </div>
    <div class="founder2-images">
      <img src="@/assets/images/founder2/1.png" alt="Image 1">
      <img src="@/assets/images/founder2/2.png" alt="Image 2">
      <img src="@/assets/images/founder2/3.png" alt="Image 3">
      <img src="@/assets/images/founder2/4.png" alt="Image 4">
      <img src="@/assets/images/founder2/5.png" alt="Image 5">
      <img src="@/assets/images/founder2/6.png" alt="Image 6">
      <img src="@/assets/images/founder2/7.png" alt="Image 7">
      <img src="@/assets/images/founder2/8.png" alt="Image 8">
      <img src="@/assets/images/founder2/9.png" alt="Image 9">
      <img src="@/assets/images/founder2/10.png" alt="Image 10">
      <img src="@/assets/images/founder2/11.png" alt="Image 11">
      <img src="@/assets/images/founder2/12.png" alt="Image 12">
    </div>
  </div>

  <div class="founder3-images">
    <img src="@/assets/images/founder3/12.png" alt="Image 12">
    <img src="@/assets/images/founder3/11.png" alt="Image 11">
  </div>
</template>

<script>
export default {
  name: "OurFounderSection",
  data() {
    return {
      currentIndex: 0,
      isTransitioning: false,
      videos: [
        { src: 'https://player.vimeo.com/video/820216888' },
        { src: 'https://www.youtube.com/embed/dc0yjcZhPy8' },
      ]
    };
  },
  computed: {
    displayedVideos() {
      const totalVideos = this.videos.length;
      return [
        this.videos[(this.currentIndex - 1 + totalVideos) % totalVideos],
        this.videos[this.currentIndex],
        this.videos[(this.currentIndex + 1) % totalVideos]
      ];
    }
  },
  methods: {
    toggleDescription(id) {
      this.activeDescription = this.activeDescription === id ? null : id;
    },
    slide(direction) {
      if (this.isTransitioning) return;
      this.isTransitioning = true;

      const wrapper = this.$refs.videoWrapper;
      const slideWidth = wrapper.clientWidth;
      const totalVideos = this.videos.length;

      if (direction === 'right') {
        this.currentIndex = (this.currentIndex + 1) % totalVideos;
        wrapper.style.transform = `translateX(-${slideWidth}px)`;
      } else {
        this.currentIndex = (this.currentIndex - 1 + totalVideos) % totalVideos;
        wrapper.style.transform = `translateX(${slideWidth}px)`;
      }

      setTimeout(() => {
        wrapper.style.transition = 'none';
        wrapper.style.transform = 'translateX(0)';
        setTimeout(() => {
          wrapper.style.transition = 'transform 0.5s ease-in-out';
          this.isTransitioning = false;
        }, 50);
      }, 500);
    }
  },
  mounted() {
    this.$nextTick(() => {
      const wrapper = this.$refs.videoWrapper;
      wrapper.style.width = '100%';
      wrapper.style.display = 'flex';
      wrapper.style.transition = 'transform 0.5s ease-in-out';
    });
  }
}
</script>

<style scoped>
.our-founder-sec1 {
  background-image: url('@/assets/images/our-founder.jpg');
  background-size: cover;
  background-position: center;
  color: white;
  font-family: 'DIN Next LT Arabic', sans-serif;
  display: flex;
  height: 100vh; 
  margin-top: -85px;
  position: relative;
  align-items: center;
  justify-content: center;
}
.founder-content {
  text-align: right; 
  padding: 2%;
}
.founder-content h1 {
  font-size: 3.5rem;
  font-weight: 500;
  font-family: 'DIN Next LT Arabic';
  line-height: 90.56px;
  padding-bottom: 20px;
}
.founder-content p {
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
  font-size: 24px;
  padding-left: 50%;
  padding-bottom: 20px;
}
.white-rectangle {
  background-color: white;
  background-size: 100% 100%;
  padding: 30px;
  width: 40%; 
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-size: 26px;
  font-weight: 500;
  line-height: 47.04px;
  text-align: center;
  color: black; 
  border-top-left-radius: 20px; 
  border-top-right-radius: 20px; 
  position: absolute; 
  bottom: 0; 
  right: 2%;
}
@media (max-width: 1200px) {
  .founder-content {
    max-width: 70%;
  }
  .founder-content h1 {
    font-size: 3rem;
  }
  .founder-content p {
    font-size: 1.2rem;
  }
}
@media (max-width: 992px) {
  .founder-content {
    max-width: 80%;
    padding-top: 8%;
  }
  .founder-content h1 {
    font-size: 2.5rem;
    line-height: 40px;
  }
  .founder-content p {
    font-size: 1.1rem;
  }
  .white-rectangle {
    width: 60%;
  }
}
@media (max-width: 768px) {
  .our-founder-sec1 {
    flex-direction: column;
    padding: 20% 5%;
    height: auto;
  }
  .founder-content {
    max-width: 90%;
    text-align: center;
    padding-top: 10%;
  }
  .founder-content h1 {
    font-size: 2rem;
  }
  .founder-content p {
    font-size: 1rem;
  }
  .white-rectangle {
    width: 100%;
    position: relative; 
    right: 0;
  }
}
@media (max-width: 576px) {
  .our-founder-sec1 {
    background-image: url('@/assets/images/bg.jpg');
    flex-direction: column;
    padding: 0;
    height: auto;
  }
  .our-founder-sec1::before {
    content: '';
    background-image: url('@/assets/images/our-founder-mob.jpg');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 50vh; 
    display: block;
  }
  .founder-content {
    max-width: 100%;
    padding-top: 2%;
    text-align: center;
    padding-bottom: 5%;
  }
  .founder-content h1 {
    font-size: 1.8rem;
  }
  .founder-content p {
    font-size: 0.9rem;
    padding-left: 0%;
  }
  .white-rectangle {
    width: 100%;
    font-size: 1rem;
    line-height: 20px;
  }
}

.our-founder-sec2 {
  background-image: url('@/assets/images/our-founder2_bg.jpg');
  background-size: cover;
  background-position: center;
  color: #165e84 !important;
  font-family: 'DIN Next LT Arabic', sans-serif;
  display: flex;
  flex-direction: column;
  height: auto;
  position: relative;
  direction: rtl;
}
.founder-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 2%;
}
.founder-image img {
  max-width: 100%;
  border-radius: 15px;
  height: 100%;
}

.founder-content-sec2 {
  flex: 1;
  padding: 10%;
  text-align: center;
}
.founder-content-sec2 h1 {
  font-size: 3.5rem;
  font-weight: 700;
  font-family: 'DIN Next LT Arabic-n';
  padding-right: 5%;c:\Users\MO_DEV_002\Downloads\Group 1171275354.png
}
.founder-content-sec2 p {
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
  font-size: 1.3rem;
  color: #74838F;
  padding-right: 5%;

}
@media (max-width: 768px) {
  .founder-content-sec2{
    padding: 0;
  }
  .founder-content-sec2 h1 {
    font-size: 1.5em;
  }
  .founder-content-sec2 p{
    font-size: 1em;
    padding: 2%;
  }
}
@media (min-width: 768px) {
  .our-founder-sec2 {
    flex-direction: row;
    height: 70vh;
    padding: 0;
  }
  .founder-image {
    justify-content: flex-end;
    margin-bottom: 0;
  }
  .founder-content-sec2 {
    text-align: right;
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .our-founder-sec2 {
    height: 85vh;
  }
  .founder-content-sec2 {
    padding-left: 2%;
  }
}

.our-founder-sec3 {
  background-image: url('@/assets/images/bg.jpg');
  background-size: cover;
  background-position: center;
  color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  height: auto;
  position: relative;
  padding: 4% 2%;
}
.founder-text {
  flex: 1;
  padding-top: 5%;
  max-width: 50%;
  margin-right: 2%;
  margin-left: 5%;
}
.founder-text h1 {
  font-size: 3.2em;
  margin-bottom: 20px;
  font-weight: 700;
  font-family: 'DIN Next LT Arabic-n';
}
.founder-text p {
  font-size: 1.2em;
  font-weight: 500;
  font-family: 'DIN Next LT Arabic';
}
.founder-images {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}
.founder-images img {
  width: 30%;
  height: auto;
  margin-bottom: 10px;
}
.founder-images img:nth-child(4) {
  width: 30%;
  height: auto;
}
.founder-images img:nth-child(5) {
  width: 65%;
  height: auto;
}
@media (max-width: 1200px) {
  .founder-text {
    max-width: 100%;
    margin: 0;
  }
  .founder-images img {
    width: 48%;
  }
  .founder-images img:nth-child(4),
  .founder-images img:nth-child(5) {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .our-founder-sec3 {
    flex-direction: column;
    align-items: center;
  }
  .founder-text {
    max-width: 100%;
    margin: 0;
    padding-top: 0;
    text-align: center;
  }
  .founder-text h1 {
    font-size: 2.4rem;
  }
  .founder-text p {
    font-size: 1.2rem;
    padding-bottom: 1em;
  }
  .founder-images {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
  }
  .founder-images img {
   width: 48%;
   height: 150px;
   margin-bottom: 10px;
  }
  .founder-images img:nth-child(4) {
   width: 48%;
   height: 150px;
  }
  .founder-images img:nth-child(5) {
  width: 100%;
  }
}

.home-sec4 {
  background-image: url('@/assets/images/Rectangle.png');
  background-size: cover;
  background-position: center;
  font-family: 'DIN Next LT Arabic-n';
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  border-radius: 0 0 2em 2em; 
  text-align: center;
  padding: 2em;
}
.t1 {
  color: #165e84;
  font-size: calc(25px + 1vw);
  font-weight: 700;
  padding: 0 1em 1em 1em;
}
.video-slider {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 60%;
  margin: 0 auto;
  border-radius: 25px;
}
.video-wrapper-immerse {
  display: flex;
  width: 50%;
}
.video-frame-home {
  flex: 0 0 100%;
  border: none;
  height: 50vw;
  max-height: 500px;
  overflow: hidden;
}
.slider-nav {
  color: #165e84;
  cursor: pointer;
  position: absolute;
  z-index: 10;
  background-color: transparent;
  transition: color 0.3s ease;
  border: none;
}
.slider-nav.left {
  left: 5vw;
}
.slider-nav.right {
  right: 5vw;
}
.slider-nav:hover {
  color: #2178a6;
}
.slider-nav:active {
  color: #fff;
}
.size-6 {
  width: 24px;
  height: 24px;
}
.player .vp-video-wrapper-immerse .vp-preview {
  border-right: 20px;
}
@media (max-width: 1000px) {
  .home-sec4 {
    border-radius: 0;
  }
  .video-frame-home {
    width: 80%;
    margin-inline: 10%;
    flex: 0 0 80%;
  }
  .slider-nav.left {
    left: 0;
  }

  .slider-nav.right {
    right: 0;
  }
}

.our-founder-sec5 {
  background-image: url('@/assets/images/bg.jpg');
  background-size: cover;
  background-position: center;
  font-family: 'DIN Next LT Arabic', sans-serif;
  color: white;
  display: flex;
  height: 70vh;
  padding: 5% 0;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  direction: rtl;
}
.founder-info {
  max-width: 50%;
  padding-right: 3%;
}
.founder-info h1 {
  font-size: 2.8rem;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
  margin-bottom: 10px;
}
.founder-info p {
  font-size: 1.3rem;
  margin-bottom: 20px;
}
.founder-info ul {
  padding: 0;
}
.founder-info ul li {
  font-size: 1rem;
  margin-bottom: 5px;
}
.founder2-images {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
  width: 45%;
  background-color: #fff;
  height: 100%;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  overflow: hidden;
  padding: 10px;
}
.founder2-images img {
  display: block;
  width: 80%;
  height: auto;
  object-fit: cover;
  margin: auto;
}
@media (max-width: 1200px) {
  .our-founder-sec5 {
    flex-direction: column;
    height: auto;
  }
  .founder-info {
    max-width: 100%;
    margin-bottom: 20px;
  }
  .founder2-images {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 768px) {
  .founder-info h1 {
    font-size: 2rem;
  }
  .founder-info p {
    font-size: 1rem;
  }
  .founder2-images {
    grid-template-columns: repeat(2, 1fr);
    border-radius: 15px;
  }
}
@media (max-width: 480px) {
  .founder-info{
    text-align: center;
  }
  .founder-info h1 {
    font-size: 1.5rem;
  }
  .founder-info p {
    font-size: 0.9rem;
  }
  .founder2-images {
    grid-template-columns: repeat(2, 1fr); 
    height: auto;
    padding: 0;
  }
}

.founder3-images {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 25px; 
    padding: 25px;
    background: #fff;
}

.founder3-images img {
    width: 100%;
    height: auto;
    display: block;
}

@media (max-width: 768px) {
.founder3-images {
    gap: 5px; 
    padding: 5px; 
}
}
</style>
