<template>
  <div class="accreditations_page">
    <div class="vision-mission-row-acc">
      <h3>معتمدون <br> دوليًا</h3>
      <p>
        أكاديمية مونجلش الدولية معتمدة من IOTQ - الولايات المتحدة وتغطي الاعتمادات أكاديمية مونجلش العالمية بأكملها. كما تحصل فرع المملكة المتحدة على ISO 9001:2015 من إدارة SAARA (معتمدة من UAF). نركز في مونجلش على تلبية وتجاوز توقعات العملاء بعن طريق تحقيق أعلي معايير الجودة.
        <br>
        . تعديل وتطوير الأنظمة القيادية والتعليمية داخل الأكاديمية
        <br>
        . تحسين معرفة المتعلمين ومهاراتهم ورفاهيتهم النفسية والاجتماعية
        <br>
        . رفع كفاءة ومستوى أداء المدرسين وأعضاء فريق خدمة العملاء
      </p>
    </div>
      
    <img src="@/assets/images/accr1.png" alt="accreditations 1" class="img_full">
  </div>

  <AccSec2 />
  <AccSec3 />
  <AccSec4 />
</template>

<script>
import AccSec2 from '../../components/ar/AccSec2.vue'; 
import AccSec3 from '../../components/ar/AccSec3.vue'; 
import AccSec4 from '../../components/ar/AccSec4.vue'; 

export default {
  name: 'AccPage',
  components: {
    AccSec2,
    AccSec3,
    AccSec4
  } 
}
</script>

<style scoped>
.accreditations_page {
  background-image: url('@/assets/images/accre_bg.jpg');
  background-size: cover;
  background-position: center;
  min-height: 110vh;
  margin-top: -85px;
  padding-top: 140px;
  font-family: 'DIN Next LT Arabic', sans-serif;
}
.vision-mission-row-acc {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-top: 20px;
  text-align: right;
  direction: rtl;
}
.vision-mission-row-acc h3 {
  width: 100%;
  margin: 0;
  padding-bottom: 20px;
  color: white;
  font-size: 2.8rem;
  font-weight: 500;
}
.vision-mission-row-acc p {
  width: 100%;
  margin: 0;
  font-size: 1.2rem;
  color: white;
  padding: 0 20px;
}
.img_full {
  margin-top: 20px;
  width: 100%;
}
@media (max-width: 768px) {
  .accreditations_page {
   height: auto;
   min-height: 82vh;
  }
 .vision-mission-row-acc {
    text-align: center;
    padding: 0;
    margin-top: 0;
  }
  .vision-mission-row-acc h3 {
    width: 100%;
    font-size: 2rem;
  }
  .vision-mission-row-acc p {
    width: 100%;
    font-size: 1.2rem;
  } 
}
@media (min-width: 768px) {
  .vision-mission-row-acc {
    flex-direction: row;
    text-align: right;
  }
  .vision-mission-row-acc h3 {
    width: 30%;
    font-size: 2.5rem;
    padding-left: 50px; 
    padding-bottom: 0;
  }
  .vision-mission-row-acc p {
    width: 70%;
    font-size: 1.4rem;
    padding-left: 50px;
  }
}
@media (min-width: 1024px) {
  .vision-mission-row-acc h3 {
    font-size: 3rem;
    padding-right: 70px;
  }
  .vision-mission-row-acc p {
    font-size: 1.6rem;
    padding-left: 70px;
  }
}
</style>
