<template>
  <main class="section1">
    <div class="section1-content">
      <div class="text-content">
        <div class="welcome flex">
          <div>مرحبا بكم في الموقع الرسمي أكاديمية مونجلش العالمية</div>
          <div>Monglish Global Network</div>
        </div>
        <h1 class="title">تعلم الإنجليزية من خلال منهج المعايشة الفريد الخاص بنا</h1>
        <p class="subtitle">أكاديمية مونجلش الدولية (المملكة المتحدة، الولايات المتحدة الأمريكية، دبي، أوزبكستان ومصر)</p>
        <ul class="circle-list">
          <li v-for="(item, index) in features" :key="index">
            <i class="fa-solid fa-circle-check"></i> {{ item }}
          </li>
        </ul>
        <div class="one-line from-row-to-column">
          <MainButton @click="goToPackages">أشترك معنا الآن</MainButton>
          <a href="/ar/accredations">
            <button class="info-btn one-line">
              المزيد من المعلومات <i class="fa-solid fa-arrow-left"></i>
            </button>
          </a>
        </div>

        <h2 class="title-secondary mt-3">اعتماداتنا و عضوياتنا</h2>
        <div class="accreditations-wrapper mt-2">
          <Carousel ref="carousel" :itemsToShow="8" :wrapAround="true" :mouseDrag="true" :autoplay="3000" :transition="500" :gap="20">
            <Slide v-for="(image, index) in accreditationImages" :key="index" class="carousel-slide">
              <img :src="image" class="reason-image" />
            </Slide>
          </Carousel>
        </div>
      </div>

      <div class="image-content hidden lg:block">
        <img src="@/assets/images/Desktop.webp" />
      </div>
    </div>

    <div class="text-center mt-8">
      <h2 class="title-2">شُركاء النجاح</h2>
      <div class="companies">
        <img v-for="(company, index) in companyLogos" :key="index" :src="company" />
      </div>
    </div>
  </main>
</template>

<script>
import MainButton from "../Elements/MainButton.vue";
import { Carousel, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default {
  name: "ViewOne",
  components: { Carousel, Slide, MainButton },
  data() {
    return {
      accreditationImages: this.loadImages('accreditations', 10),
      companyLogos: this.loadImages('companies', 8),
      features: [
        "معتمدون دولياً",
        "وسائل التعلم التفاعلية",
        "دعم طوال الأسبوع",
      ],
    };
  },
  methods: {
    goToPackages() {
      this.$router.push({ name: "PackagesViewAr" });
    },
    loadImages(folder, count) {
      return Array.from({ length: count }, (_, index) =>
        require(`@/assets/images/${folder}/${index + 1}.webp`)
      );
    },
  },
};
</script>

<style src="@/assets/styles/ar/ViewOne.css" scoped></style>
